import React from 'react'
import LazyLoad from 'react-lazyload'

const Facilitiesprop = (props) => {
  return (
      <>
            <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="facilitiesbox">
                     <LazyLoad><img src={props.image}/></LazyLoad>
                     <h3>{props.title}</h3>
                     <p>{props.desc}</p>
                  </div>
            </div>
      </>
  )
}

export default Facilitiesprop
